exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-who-taken-js": () => import("./../../../src/pages/who-taken.js" /* webpackChunkName: "component---src-pages-who-taken-js" */),
  "component---src-pages-why-train-js": () => import("./../../../src/pages/why-train.js" /* webpackChunkName: "component---src-pages-why-train-js" */)
}

